.area-empty {
  color: rgba(255, 255, 255, .3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.empty-icon {
  max-width: 180px;
  margin-bottom: 20px;
}