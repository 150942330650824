.noWalletPopup{
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 45vh; */
    .wallets{
        padding-top: 6px;
        display: flex;
        justify-content: center;
        height: 114px;
        margin-bottom: 20px;
        .item{
            width: 100px;
            padding-left: 20px;
            padding-right: 20px;
            .itemImg{
                margin-top: 10px;
                margin-bottom: 8px;
                width: 60px;
                height: 60px;
                border-radius: 15px;
            }
            .itemDesc{
                color: #fff;
                display: inline-block;
                text-align: center;
                width: 100%;
            }
        }
    }
    .titleDiv{
        padding-left: 18px;
        padding-right: 18px;
        width: 100%;
    }
    .content{
        flex: 1;
        background-color: #1a1b1f;
        width: 100%;
        .contentBtm{
            display: flex;
            justify-content: space-around;
            padding-bottom: 36px;
        }
    }
}

.btn{
    padding: 10px 24px 10px 24px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 20px;
    color: #3898FF;
    font-weight: 700;
    font-size: 16px;
}

.getWallet{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    .getWalletContent{
        margin-top: -10px;
    }
}

.contentBlock{
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    font-size: 16px;
    .title{
        color: #fff;
        width: 100%;
        margin-bottom: 10px;
        font-weight: 700;
        text-align: center;
    }
    .blockContent{
        margin-top: 10px;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
        width: 100%;
        text-align: center;
    }
}

.popupTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
}