.commonTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .backBtn{
        color: #3898FF;
        height: 32px;
        display: flex;
        align-items: center;
    }
    .modalCloseBtn{
        text-align: center;
        vertical-align: middle;
        display: flex;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.08);
        color: rgba(224, 232, 255, 0.6);
    }
    .title{
        width: 100%;
        text-align: center;
        width: 100%;
        color: #fff;
        font-size: 20px;
        height: 32px;
        margin-bottom: 4px;
        font-weight: 700;
    }
}
