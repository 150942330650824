
/* chain modal content */
.modal-dialog-scrollable .chain-modal-content {
	max-height: 100%;
	overflow: hidden;
}
.chain-modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #191A1A;
	background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.3));
	background-clip: padding-box;
	border-radius: 20px;
	outline: 0;
	letter-spacing: normal;
}
.chain-modal-header {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	padding: 1.2rem 2rem;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	text-align: center;
}
.chain-modal-header .modal-title {
	font-weight: 600;
	font-size: 22px;
	color: rgba(255, 255, 255, .8);
}
.chain-pop-close {
	position: absolute;
	right: 2rem;
	top: 1.5rem;
}
.chain-pop-close svg {
	width: 20px;
	height: 20px;
	fill: #ffffff;
	opacity: 0.6;
	-webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.chain-pop-close:hover svg {
	opacity: 1;
}
.chain-modal-body {
	overflow-y: auto;
	position: relative;
}
.switch-desc {
	text-align: center;
	font-size: 0.875rem;
	font-weight: 500;
	margin-top: 1rem;
}
.chain-content {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 1rem;
	max-height: 400px;
	overflow: auto;
}
.chain-btn {
	display: inline-flex;
	gap: 1rem;
	align-items: center;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	border: 0px;
	border-radius: 1rem;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 700;
	letter-spacing: 0.03em;
	line-height: 1;
	opacity: 1;
	outline: 0px;
	transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
	min-height: 58px;
	padding: 16px 24px;
	background-color: #1E1E1E;
	color: rgb(31, 199, 212);
	box-shadow: none;
	width: 100%;
}
.chain-text {
	color: rgba(255, 255, 255, .8);
}
.chain-btn:hover {
	background-color: #2d2d2d;
}
.orline {
	position: relative;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-weight: 500;
	line-height: 1;
	font-size: 1.125rem;
	margin: -0.5rem 0;
	color: rgba(255, 255, 255, .5);
}
.orline::before,.orline::after {
	background-color: rgba(255,255,255,0.1);
	content: "";
	height: 0.0625rem;
	width: 100%;
}
.chain-modal-footer .chain-text {
	color: rgba(255, 255, 255, .5);
}

