.walletItem{
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    .icon{
        width: 48px;
        height: 48px;
        border-radius: 15px;
        margin-right: 20px;
    }
    .desc{
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        text-align: left;
        flex: 1;
    }
    .linkBtn{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 18px;
        color:  rgb(56, 152, 255);
        font-size: 14px;
        font-weight: 700;
        background-color: rgba(255, 255, 255, 0.08);
        height: 28px;
        padding-left: 15px;
        padding-right: 15px;
    }
}