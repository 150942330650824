/* invitation code */

.invitation-code-content {
    width: 100%;
    min-height: 100%;
    position: relative;
    background-image: url('https://assets.outer.gg/frontend/img/invitation/invitation-code-back.png');
    background-size: cover;
    background-position: top bottom;
    text-align: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    background-attachment: fixed;
}
.invitation-code-content:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('https://assets.outer.gg/frontend/img/privilege/pixel.webp');
    background-size: 100%;
    background-position: center;
    opacity: 0.5;
    background-color: rgba(0,0,0,.3);
	pointer-events: none;
}

.invitation-code-hd {
    display: flex;
    align-items: center;
    justify-content: space-between;
	flex-wrap: wrap;
    border-bottom: 1px solid rgba(255,225,225,.20);
    width: calc(100% - 0px);
    min-height: 78px;
    position: sticky;
    top: 92px;
    background: rgba(0,0,0,.2);
	-webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    z-index: 2;
    padding: 0 1.5rem;
}
.inv-hd-l {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
	font-weight: 600;
}

.inv-hd-r {
    display: flex;
    align-items: center;
}
.inv-info {
    margin-right: 1rem;
}
.inv-tit {
    color: #ffffff;
}
.inv-desc {
    font-size: 12px;
}
.inv-points {
    background: linear-gradient(90deg, #6AFFC0 40%, #F9F586 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 0px 20px rgba(255,244,0,50%);
}

.inv-content {
    margin: 3rem 0;
    backdrop-filter: blur(20px);
    background-image: linear-gradient(#000,rgba(10,23,23,.5));
    border: 1px solid rgb(33 226 225 / 20%);
    padding: 2rem;
    border-radius: 10px;
}
.inv-content-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid rgba(34,226,226,.1);
    font-size: 18px;
    overflow: hidden;
}
.inv-content-list:last-child {
    border-bottom: 1px solid rgba(34,226,226,0);
}
.username-avatar {
    width: 42px;
    height: 42px;
    background: rgba(34,226,225,0.2);
    border: 1px solid rgba(34,226,225,1);
    padding: 2px;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
}
.username-avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}
.list-front {
    display: flex;
    align-items: center;
    width: 100%;
}
.codenumber {
    padding: 0 1rem;
    background: linear-gradient(90deg, #84FAB0 0%, #8FD3F4 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.codenumber-grey {
    padding: 0 1rem;
    background: linear-gradient(90deg, #ffffff 0%, #c8c8c8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    word-wrap: break-word;
    word-break: normal;
    width: 100%;
}
.list-end {
    display: flex;
    align-items: center;
    width: 100%;
}
.link-addr {
    padding: 0 1rem;
    word-wrap: break-word;
    word-break: normal;
    width: 100%;
}
.copy-btn {
    min-width: 100px;
    background-color: #21e2e1;
    display: inline-block;
    line-height: 1.3;
    color: #000;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: none;
    padding: 1px;
    border-radius: 0.25rem;
    -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    clip-path: polygon(10px 0px, 100% 0px, calc(100% - 5px) 0px, 100% 5px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 5px 100%, 0px calc(100% - 5px), 0px 10px);
}
.copy-btn-text {
    padding: 0.375rem 1rem;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    color: #21e2e1;
    clip-path: polygon(10px 0px, 100% 0px, calc(100% - 5px) 0px, 100% 5px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 5px 100%, 0px calc(100% - 5px), 0px 10px);
    background-color: #000000;
    background-image: linear-gradient(180deg,#000,rgb(10 23 23)),linear-gradient(180deg,rgb(0 236 235),#11b9b8);
}
.copy-btn:disabled {
    background-color: #9b9c9c;
}
.copy-btn:disabled .copy-btn-text {
    color: #9b9c9c;
}


.inv-acc-content-warp {
	margin: 60px 0;
	background-color: rgba(0,0,0,.5);
	background-image: linear-gradient(180deg,#000,rgba(6,35,35,.5)),linear-gradient(180deg,rgba(33,226,225,.3),rgba(6,35,35,0));
	border: 1px solid transparent;
	background-clip: padding-box,border-box;
	background-origin: padding-box,border-box;
}

/* games switch tabs */
.inv-games-switch-hd {
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid rgb(30 181 177 / 15%);
	width: calc(100% - 0px);
	min-height: 64px;
	background: linear-gradient(92deg, #021414 8%, #030e0e 100%);
	z-index: 1;
}
.inv-games-switch {
    display: flex;
    align-items: center;
    max-width: 100%;
}
.inv-games-switch-li {
	position: relative;
    padding: 1.25rem 0;
	cursor: pointer;
	margin:0 1rem;
}
.inv-games-switch-logo {
    max-width: 130px;
    opacity: 0.6;
    -webkit-filter:grayscale(100%); 
    filter:grayscale(100%); 
}
.inv-games-switch-li.active .inv-games-switch-logo {
    opacity: 1;
    -webkit-filter:grayscale(0); 
    filter:grayscale(0); 
}
.inv-games-switch-li:before {
	content: '';
	position: absolute;
	height: 1px;
	width: 100%;
	left: 0;
	bottom: 0;
	transform-origin: 100% 50%;
	transform: scale3d(0, 1, 1);
	transition: transform 0.3s;
	background: linear-gradient(92deg, #8BFFF8 8%, #EFFCCA 100%);
}
.inv-games-switch-li.active:before {
	transform-origin: 0% 50%;
	transform: scale3d(1, 1, 1);
}

.inv-acc-content {
    padding: 3rem 2rem;
    border-radius: 20px;
    max-width: 100%;
    width: 100%;
    min-height: 300px;
}
.inv-acc-hd {
    color: rgba(255, 255, 255, .7);
    margin-bottom: 20px;
}
.inv-acc-bd {
	max-width: 600px;
	margin: 0 auto;
    border-radius: 10px;
    background: rgba(0,0,0,.2);
    border: 1px solid rgb(33 226 225 / 20%);
}
.inv-acc-fb {
	padding: 1rem 0;
}
.activation-successful {
    min-height: 108px;
	background-image: url('https://assets.outer.gg/frontend/img/invitation/activated-img.png');
	background-position: left center;
	background-repeat: no-repeat;
	max-width: 550px;
	margin: 2rem auto;
	padding: 2.3rem 0 0 6rem;
}
.activation-successful-text {
	position: relative;
	background: linear-gradient(90deg, #84FAB0 0%, #8FD3F4 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 18px;
	font-weight: 700;
	font-style: oblique;
}

.invcode {
    text-shadow: 0px 0px 10px rgba(0, 255, 209, 0.51);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
	font-weight: 600;
    letter-spacing: 1.2px;
    background: linear-gradient(90deg, #84FAB0 0%, #8FD3F4 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.inv-acc-desc {
    font-size: 18px;
    color: #ffffff;
	font-weight: 500;
}
.inv-acc-desc-sub {
}
.inv-acc-item {
    padding: 1rem 1.5rem;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(33,226,225,.1);
	min-height: 70px;
}
.inv-acc-item:last-child {
    border-bottom: 1px solid transparent;
}
.inv-acc-item-label {
    min-width: 100px;
    margin-right: 10px;
	font-weight: 600;
	text-align: left;
}
.inv-acc-item-control {
    flex: 1;
    text-align: center;
    color: #ffffff;
	font-weight: 600;
}
.inv-acc-form-control {
	width: 100%;
    padding: 0.5rem 1rem;
    background: transparent;
    color: #21e2e1;
    background-color: rgba(33,226,225,.1);
    border: 0;
    flex: 1;
    text-align: center;
    border-radius: 10px;
	font-weight: 600;
	font-size: 1.2rem;
	border: 1px solid rgba(33,226,225,.2);
	letter-spacing: 0.3rem;
}
.inv-acc-form-control::-moz-placeholder {
    color: rgba(33,226,225,.3);
    opacity: 1;
	letter-spacing: 0;
	font-weight: 400;
}

.inv-acc-form-control::placeholder {
    color: rgba(33,226,225,.3);
    opacity: 1;
	letter-spacing: 0;
	font-weight: 400;
}




@media (max-width:1199.98px) {
	.invitation-code-hd {
		padding: 1rem;
		top: 72px;
		min-height: inherit;
		border-color: transparent;
	}
}
@media (max-width: 991.98px) {
	.main-content-bd {
		padding: 0;
	}
	
	.inv-hd-r {
		flex: 1;
		justify-content: space-between;
	}
    .inv-content-list {
        flex-direction: column;
	}
	.inv-info {
		text-align: left;
	}
	.list-front {
		flex-direction: column;
	}
	.codenumber {
		font-size: 1.5rem;
		font-weight: 700;
	}
	.list-end {
		flex-direction: column;
	}
	.inv-acc-content-warp {
		margin: 20px 0;
	}
	.inv-games-switch-hd {
		overflow-x: auto;
		justify-content: flex-start;
	}
	/* 滚动条样式 */
	.inv-games-switch-hd::-webkit-scrollbar {
	    width: 0px; /* 设置滚动条宽度 */
		height: 2px;
	}
	 
	.inv-games-switch-hd::-webkit-scrollbar-thumb {
	    background-color: rgb(30 181 177 / 10%); /* 设置滑块颜色 */
	    border-radius: 0; /* 设置滑块边角半径 */
	}
	 
	.inv-games-switch-hd::-webkit-scrollbar-track {
	    background-color: transparent; /* 设置轨道颜色 */
	}
	 
	.inv-games-switch-hd::-webkit-scrollbar-button {
	    display: none; /* 隐藏滚动条两侧的按钮 */
	}
	 
	.inv-games-switch-hd::-webkit-scrollbar-corner {
	    display: none; /* 隐藏交叉点 */
	}
	 
	.inv-games-switch-hd::-webkit-resizer {
	    display: none; /* 隐藏调整元素大小手柄 */
	}
	
	.inv-games-switch-logo {
		max-width: 110px;
		opacity: 0.6;
	}
	.inv-acc-content {
		padding: 3rem 1rem;
	}
	.inv-acc-item-control {
		word-wrap: break-word;
		width: 100%;
	}
	.inv-acc-item {
		flex-direction: column;
	}
	.inv-acc-item-label {
		text-align: center;
	}
}
