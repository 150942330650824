
.alert-top {
	background-color: rgba(125, 80, 49, 0.4);
	padding: 0.4rem 0.75rem;
	color: #FFDC6F;
	display: flex;
	justify-content: center;
	position: fixed;
	left: 0;
	width: 100%;
	top: 90px;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	z-index: 1020;
}
.alert-icon {
	margin-right: 0.5rem;
	width: 30px;
	height: 30px;
}
.alert-top .alert-icon {
	width: 1.5rem;
}

.toast-div{
	display: flex;
	
}


.alert-rt-box {
	position: fixed;
	right: 1rem;
	top: 9rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 1020;
}
.alert-rt {
	display: flex;
	align-items: flex-start;
	background-color: rgba(40, 40, 40, .9);
	padding: 1rem;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	overflow: hidden;
}
.alert-rt.success {
	background-image: linear-gradient(226deg, transparent 50%, #47626240 100%);
}
.alert-rt.warning {
	background-image: linear-gradient(226deg, transparent 50%, #a7805240 100%);
}
.alert-tit {
	font-size: 1.2rem;
	font-weight: 700;
	color: #ffffff;
}
.alert-bd {
	max-width: 20rem;
	min-width: 10rem;
}
.alert-des {
	line-height: 1.5;
	color: rgba(255,255,255,.7);
}
.alert-close:hover .i-alert-close {
	fill: #ffffff;
}
.i-alert-close {
	fill: rgba(255,255,255,.5);
}

.success .alert-tit {
	color: #12FFF7;
}
.warning .alert-tit {
	color: #FFDC6F;
}

.countdown-bar {
	position: absolute;
	height: 0.15rem;
	width: 100%;
	left: 0;
	bottom: 0;
	background-color: rgba(255,255,255,.1);
}

.countdown-cur {
	position: absolute;
	left: 0;
	height: 100%;
	background-color: rgba(255,255,255,.9);
}
.success .countdown-cur {
	background-image: linear-gradient(92deg, #84FAB0 50%, #8FD3F4 100%);
}
.warning .countdown-cur {
	background-image: linear-gradient(92deg, #FFDC6F 50%, #FFDC6F 100%);
}



.alert-c {
	display: flex;
	align-items: center;
	background: rgba(40, 40, 40, .4);
	border: 1px solid rgba(255, 255, 255, .2);
	padding: 1rem;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	max-width: 30rem;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 1020;
}
.alert-c .alert-icon {
	margin: 1rem;
}
.alert-c .alert-close {
	align-self: flex-start;
}


@media (max-width:1399.98px) {
	
}
@media (max-width:1199.98px) {
	
}
@media (max-width: 767.98px) {
	.alert-top {
		top: 72px;
		font-size: 0.8rem;
		line-height: 1;
	}
	.alert-rt-box {
		right: inherit;
		top: 8rem;
		align-items: center;
		padding: 0.75rem;
	}
	.alert-tit {
		font-size: 1rem;
	}
}

@keyframes proccessBar
{
    from {width: 100%}
    to {width: 1.5%;}
}