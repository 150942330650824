* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	text-decoration: none;
	list-style: none;
	outline: none;
	margin: 0;
	padding: 0;
}

.Toastify__toast-theme--dark {
	background: rgba(40, 40, 40, .9) !important;
}	

/* 解决电脑屏幕显示比例缩放对页面布局的影响 */
@media all
and (-moz-min-device-pixel-ratio: 1.09) and (-moz-max-device-pixel-ratio: 1.18),
    (-webkit-min-device-pixel-ratio: 1.09) and (-webkit-max-device-pixel-ratio: 1.18),
    (min-resolution: 1.09dppx) and (max-resolution: 1.18dppx) {
    :root {
        font-size: 14px;
    }
}
@media all
and (-moz-min-device-pixel-ratio: 1.19) and (-moz-max-device-pixel-ratio: 1.28),
    (-webkit-min-device-pixel-ratio: 1.19) and (-webkit-max-device-pixel-ratio: 1.28),
    (min-resolution: 1.19dppx) and (max-resolution: 1.28dppx) {
    :root {
        font-size: 14px;
    }
}
@media all
and (-moz-min-device-pixel-ratio: 1.29) and (-moz-max-device-pixel-ratio: 1.4),
    (-webkit-min-device-pixel-ratio: 1.29) and (-webkit-max-device-pixel-ratio: 1.4),
    (min-resolution: 1.29dppx) and (max-resolution: 1.4dppx) {
    :root {
        font-size: 14px;
    }
}
@media all
and (-moz-min-device-pixel-ratio: 1.41) and (-moz-max-device-pixel-ratio: 1.6),
    (-webkit-min-device-pixel-ratio: 1.41) and (-webkit-max-device-pixel-ratio: 1.6),
    (min-resolution: 1.41dppx) and (max-resolution: 1.6dppx) {
    :root {
        font-size: 12.5px;
    }
}
@media all
and (-moz-min-device-pixel-ratio: 1.61) and (-moz-max-device-pixel-ratio: 1.8),
    (-webkit-min-device-pixel-ratio: 1.61) and (-webkit-max-device-pixel-ratio: 1.8),
    (min-resolution: 1.61dppx) and (max-resolution: 1.8dppx) {
    :root {
        font-size: 14px;
    }
}
@media all
and (-moz-min-device-pixel-ratio: 1.81) and (-moz-max-device-pixel-ratio: 2.1),
    (-webkit-min-device-pixel-ratio: 1.81) and (-webkit-max-device-pixel-ratio: 2.1),
    (min-resolution: 1.81dppx) and (max-resolution: 2.1dppx) {
    :root {
        font-size: 14px;
    }
}

/* 密码是否可见开关图标 */
.eye-icon {
	fill: #90a4ae;
	color: #90a4ae;
	cursor: pointer;
	position: absolute;
	right: .1rem;
	top: 2.2rem;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-Regular.woff2') format('woff2'),
        url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Euclid Circular A';
    src: url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-Italic.woff2') format('woff2'),
        url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Euclid Circular A';
    src: url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-Medium.woff2') format('woff2'),
        url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-MediumItalic.woff2') format('woff2'),
        url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-SemiBold.woff2') format('woff2'),
        url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-SemiBoldItalic.woff2') format('woff2'),
        url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-Bold.woff2') format('woff2'),
        url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-BoldItalic.woff2') format('woff2'),
        url('https://assets.outer.gg/frontend/fonts/EuclidCircularA/EuclidCircularA-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aldrich';
    src: url('https://assets.outer.gg/frontend/fonts/Aldrich/Aldrich-Regular.woff2') format('woff2'),
        url('https://assets.outer.gg/frontend/fonts/Aldrich/Aldrich-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


h1,h2,h3,h4,h5,h6 {
	color: #fff;
	font-weight: 600;
	line-height: 1.2;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: initial;
}

h1 {
	font-size: 3.5rem;
}
a {
	color: #ffffff;
	text-decoration: none 
}

a:hover {
	color: rgba(255,255,255,.5);
	text-decoration: none
}

a:focus {
	text-decoration: none;
}
@media (max-width: 767.98px) {
	h1 {
		font-size: 2.5rem;
	}
}

body {
	background-color: #121212;
    line-height: 1.6;
    /* color: rgba(255, 255, 255, 0.6) !important; */
    font-family: 'Euclid Circular A', sans-serif !important;
    margin: 0;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-size: 1rem !important; */
    letter-spacing: 0.02rem;
}



.cf-content {
    position: relative;
}
.sf-hero {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 0;
}
.sf-hero:before {
	position: absolute;
    z-index: 1;
    content: '';
    background: linear-gradient(to bottom right, rgba(0, 255, 255, 0.62) 0%, rgba(0, 194, 255, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(0, 255, 255, 0.62) 0%, rgba(0, 194, 255, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(0, 255, 255, 0.62) 0%, rgba(0, 194, 255, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(0, 255, 255, 0.62) 0%, rgba(0, 194, 255, 0.00) 50%) top right / 50% 50% no-repeat;
    background-color: rgba(0, 0, 0, .4);
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
}
.sf-hero-cover {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}
.sf-hero-video {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.sf-hero-content {
    position: relative;
    z-index: 1;
    text-align: center;
}
.crystalfun-introduce {
	font-size: 1.8rem;
	font-weight: 500;
	background: linear-gradient(90deg, #FFF1EB 0%, #ACE0F9 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-bottom: 2rem;
}

@media (max-width: 767.98px) {
	.hero-logo {
		width: 80%;
	}
}
@media (max-width: 991.98px) {
	
	.crystalfun-introduce {
		font-size: 1rem;
	}
}

.funbtn-outer {
	/*padding: 0.1rem;
	clip-path: polygon(5% 15%, 0 75%, 5% 100%, 88% 100%, 90% 85%, 96% 85%, 100% 25%, 95% 0, 12% 0, 10% 15%);
	background: linear-gradient(90.93deg, #00d2c5 7.64%, #ffdd00 97.66%);
	display: inline-flex;*/
	filter: drop-shadow(0px 0px 10px rgba(5, 255, 226, 0.5));
}

.funbtn {
	background: linear-gradient(92deg, #8BFFF8 8%, #EFFCCA 100%);
    color: #141414;
    min-width: 190px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 700;
/*    font-family: casanova scotia;*/
    font-size: 1.5rem;
/*  clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);*/
/*  clip-path: polygon(5% 0, 0 70%, 5% 100%, 95% 100%, 100% 30%, 95% 0);*/
/*	clip-path: polygon(5% 15%, 0 75%, 5% 100%, 85% 100%, 87% 85%, 96% 85%, 100% 25%, 95% 0, 15% 0, 13% 15%);*/
	clip-path: polygon(5% 15%, 0 75%, 5% 100%, 88% 100%, 90% 85%, 96% 85%, 100% 25%, 95% 0, 12% 0, 10% 15%);
    padding: 0.75rem 3rem;
    letter-spacing: 0.05rem;
}


.funbtn-text {
	background-image: -webkit-linear-gradient(90deg, #78ff00 0%, #658800 50%,#00e5a6 100%);
	background-image: linear-gradient(90deg, #78ff00 0%, #658800 50%,#00e5a6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    transition: all 0.5s;
	
	-webkit-animation: background-change-data-v-39122f74 3s linear infinite;
	        animation: background-change-data-v-39122f74 3s linear infinite;
}

.funbtn:hover {
	color: #141414;
	letter-spacing: 0.08rem;
}
.funbtn:hover .funbtn-text {
	background-image: -webkit-linear-gradient(90deg, #78ff00 0%, #658800 50%,#00e5a6 100%);
	background-image: linear-gradient(90deg, #78ff00 0%, #658800 50%,#00e5a6 100%);
	-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}



@-webkit-keyframes background-change-data-v-39122f74 {
0% {
    background-size: 100% 100%;
    background-position: 0 0;
}
50% {
    background-size: 300% 150%;
    background-position: 100% 50%;
}
100% {
    background-size: 100% 100%;
    background-position: 0% 0%;
}
}
@keyframes background-change-data-v-39122f74 {
0% {
    background-size: 100% 100%;
    background-position: 0 0;
}
50% {
    background-size: 300% 150%;
    background-position: 100% 50%;
}
100% {
    background-size: 100% 100%;
    background-position: 0% 0%;
}
}

@media (max-width: 767.98px) {
	.funbtn {
		font-size: 1.3rem;
		padding: 0.35rem 1.75rem;
		}
}



.mouse {
	position: absolute;
    width: 30px;
    height: 48px;
    border-radius: 15px;
	left: 50%;
    -webkit-transform: translate3d(-50%,0,0);
    transform: translate3d(-50%,0,0);
    bottom: 15px;
    z-index: 2;
    border: 2px solid #fff;
}
.mouseWheel {
	background-color: #fff;
	width: 4px;
    height: 9px;
    position: absolute;
    top: 8px;
    left: 11px;
    border-radius: 2px;
    -webkit-animation: Mouse__mouseWheel--3X3Wk 1.25s infinite linear;
    animation: Mouse__mouseWheel--3X3Wk 1.25s infinite linear;
}

@-webkit-keyframes Mouse__mouseWheel--3X3Wk {
    0% {
        -webkit-transform: scaleY(.4) translateY(0);
        transform: scaleY(.4) translateY(0)
    }

    20% {
        -webkit-transform: scaleY(1) translateY(0);
        transform: scaleY(1) translateY(0)
    }

    60% {
        -webkit-transform: scaleY(.4) translateY(12px);
        transform: scaleY(.4) translateY(12px)
    }

    to {
        -webkit-transform: scaleY(.4) translateY(0);
        transform: scaleY(.4) translateY(0)
    }
}

@keyframes Mouse__mouseWheel--3X3Wk {
    0% {
        -webkit-transform: scaleY(.4) translateY(0);
        transform: scaleY(.4) translateY(0)
    }

    20% {
        -webkit-transform: scaleY(1) translateY(0);
        transform: scaleY(1) translateY(0)
    }

    60% {
        -webkit-transform: scaleY(.4) translateY(12px);
        transform: scaleY(.4) translateY(12px)
    }

    to {
        -webkit-transform: scaleY(.4) translateY(0);
        transform: scaleY(.4) translateY(0)
    }
}



.ourgames-section {
	position: relative;
	background-color: #141414;
	padding: 2rem 0 5rem 0;
	/* background-image: url(../img/crystalfun/lightback.svg); */
	background-size: 130%;
	background-repeat: no-repeat;
	background-position: center 0;
	z-index: 1;
}
@media (max-width: 767.98px) {
	.ourgames-section {
		background-size: cover;
	}
}
.section-tit {
	padding: 3rem 0;
}
@media (max-width: 767.98px) {
	.section-tit {
		text-align: center;
		padding: 2rem 0;
	}
}
.games-grid {
	display: grid;
    grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
    gap: 4rem;
    padding: 2rem 0;
}
@media (max-width: 991.98px) {
	.games-grid {
	    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	    gap: 2rem;
	    padding: 2rem 0;
	}
}
@media (max-width: 1199.98px) {
	.games-grid {
	    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	    gap: 2rem;
	    padding: 2rem 0;
	}
}
.games-show {
	background: rgba(68, 68, 68, 0.5);
	width: 100%;
	height: 100%;
	min-height: 500px;
	padding-bottom: 1rem;
	padding: 1px;
	-webkit-transition: all 400ms cubic-bezier(.9,0,.1,1);
	transition: all 400ms cubic-bezier(.9,0,.1,1);
	clip-path: polygon(50px 0px, 100% 0px, calc(100% - 0px) 0px, 100% 0px, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0px 100%, 0px calc(100% - 0px), 0px 50px);
}

.games-show-content {
	display: flex;
	flex-direction: column;
	height: 100%;
	background: rgba(33, 33, 33, 0.5);
	clip-path: polygon(50px 0px, 100% 0px, calc(100% - 0px) 0px, 100% 0px, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0px 100%, 0px calc(100% - 0px), 0px 50px);
}
@media (max-width: 767.98px) {
	.games-show-content {
		text-align: center;
	}
}
.games-cover-img {
	width: 100%;
	clip-path: polygon(0% 0%, 0 100%, 0% 100%, 85% 100%, 90% 95%, 100% 95%, 100% 0%, 100% 0, 0% 0, 0% 0%);
}
.games-desc {
	flex: 1;
	color: #f2f2f2;
    font-weight: 500;
    padding: 3rem;
}

.desc-li-item {
	padding: 2rem 0;
    border-top: 1px solid rgba(255,255,255,.1);
    margin: 0 3rem;
    display: flex;
    justify-content: space-around;
}
.desc-li-item label {
	margin: 0 0.3rem 0.5rem 0;
	font-size: 18px;
}
@media (max-width: 767.98px) {
	.desc-li-item label {
		font-size: 16px;
	}
}


.games-Genre {
	color: #ffffff;
	font-weight: bold;
	font-style: italic;
    font-size: 1.8rem;
    background: linear-gradient(92deg, #8BFFF8 8%, #EFFCCA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.go-visit {
	color: #ffffff;
    font-weight: 500;
    padding: 0.65rem 1rem;
    font-size: 14px;
    border: 1px solid rgba(255,255,255,.1);
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 0.5rem;
    background: rgba(255,255,255,.05);
    transition: all 0.3s;
}
.i--visit {
	fill: #ffffff;
}
.go-visit:hover {
	color: rgba(255, 255, 255, .9);
	border-color: rgba(255, 255, 255, .2);
}
.go-visit:hover .i--visit {
	fill: rgba(255, 255, 255, .9);
}

.go-visit:disabled {
	pointer-events: none !important;
    opacity: 0.5 !important;
}


.dividingLine {
	width: 1px;
    background: rgba(255,255,255,.1);
}
.desc-li {
	margin: 0.3rem 0;
	display: flex;
	flex-direction: column;
	
}
@media (max-width: 767.98px) {
	.games-desc {
		padding: 1.5rem;
	}
	.desc-li-item {
		margin: 0 1.5rem;
	}
	.go-visit span {
		display: none;
	}
	.desc-li {
		align-items: center;
	}
}
.platforms {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 1rem;
    margin: 0;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.2);
    position: relative;
    bottom: 0;
    width: 100%;
    clip-path: polygon(0% 10%, 0 100%,05% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 0%, 100% 0, 10% 0, 5% 10%);
}
.platforms-item {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	flex: 1;
    border:1px solid transparent;
    transition: all 0.3s;
    border-radius: 5px;
    text-align: center;
    padding: 1.5rem;
    border-radius: 1rem;
    cursor: pointer;
}
.platforms-item:hover {
	background-color: rgba(0,0,0,0);
}
.platforms-name {
	font-weight: 500;
    font-size: 18px;
    color: rgba(255, 255, 255, .7);
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}
.platforms-icon {
	display: inline-flex;
	width: 42px;
    height: 42px;
    align-items: center;
    justify-content: center;

}
.platforms-icon img {
	max-width: 30px;
}
.icon-darrow {
	fill: #ffffff;
	width: 10px;
}
.btndownload {
	padding: 0.5rem 1rem;
	font-size: 14px;
/*	border: 1px solid rgba(255,255,255,.3);*/
	border-radius: 1rem;
    background: rgba(255,255,255,.1);
    transition: all 0.3s;
}
.btndownload:hover {
	color: #ffffff;
	background: rgba(255,255,255,.2);
}
.btndownload:hover .icon-darrow {
	fill: #ffffff;
}
.platforms-item.disabled {
	pointer-events: none;
	cursor: none;
	opacity: 0.6;
}
.platforms-item.disabled .btndownload {
	border-color: rgba(255,255,255,.4);
	opacity: 0.6;
}

@media (max-width: 767.98px) {
	.btndownload span {
		display: none;
	}
	.platforms-item {
		padding: 1.5rem 0;
	}
}


.subscribe-section {
	padding: 3rem 0;
	min-height: 700px;
	/* background-image: url(../img/crystalfun/subscribe-bg.png); */
	background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}
@media (max-width: 767.98px) {
	.subscribe-section {
		min-height: inherit;
	}
}


.subscribe-module {
	display: flex;
    align-items: center;
    border: 1px solid #A4EBF7;
    background: rgba(0, 0, 0, 0.30);
    -webkit-transform: skewX(-10deg);
    transform: skewX(-10deg);
    max-width: 620px;
    margin: 0 auto;
}
@media (max-width: 767.98px) {
	.subscribe-module {
		display: block;
	}
}

.tit-desc {
	font-size: 18px;
	color: #8A8889;
	margin: 1rem 0;
}
.subscribe-form-control  {
	display: block;
	width: 100%;

	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: transparent;
	background-clip: padding-box;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

	min-height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
}
.subscribe-form-control:focus {
	color: #ffffff;
	background-color: transparent;
	border-color: #86b7fe;
	outline: 0;
	
	box-shadow: inherit;
}
.btn-subscribe {
	background: linear-gradient(90deg, #B3FFAB 0%, #12FFF7 100%);
    color: #141414;
    min-width: 190px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0.5rem;
}
.btn-subscribe:hover {
	color: #141414;
}


/* chain modal content */
.modal-dialog-scrollable .chain-modal-content {
	max-height: 100%;
	overflow: hidden;
}
.chain-modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #191A1A;
	background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.3));
	background-clip: padding-box;
	border-radius: 20px;
	outline: 0;
	letter-spacing: normal;
}
.chain-modal-header {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	padding: 1.2rem 2rem;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	text-align: center;
}
.chain-modal-header .modal-title {
	font-weight: 600;
	font-size: 22px;
	color: rgba(255, 255, 255, .8);
}
.chain-pop-close {
	position: absolute;
	right: 2rem;
	top: 1.5rem;
}
.chain-pop-close svg {
	width: 20px;
	height: 20px;
	fill: #ffffff;
	opacity: 0.6;
	-webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.chain-pop-close:hover svg {
	opacity: 1;
}
.chain-modal-body {
	overflow-y: auto;
	position: relative;
}
.switch-desc {
	text-align: center;
	font-size: 0.875rem;
	font-weight: 500;
	margin-top: 1rem;
}
.chain-content {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 1rem;
}
.chain-btn {
	display: inline-flex;
	gap: 1rem;
	align-items: center;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	border: 0px;
	border-radius: 1rem;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 700;
	letter-spacing: 0.03em;
	line-height: 1;
	opacity: 1;
	outline: 0px;
	transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
	min-height: 58px;
	padding: 16px 24px;
	background-color: #1E1E1E;
	color: rgb(31, 199, 212);
	box-shadow: none;
	width: 100%;
}
.chain-text {
	color: rgba(255, 255, 255, .8);
}
.chain-btn:hover {
	background-color: #2d2d2d;
}
.orline {
	position: relative;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-weight: 500;
	line-height: 1;
	font-size: 1.125rem;
	margin: -0.5rem 0;
	color: rgba(255, 255, 255, .5);
}
.orline::before,.orline::after {
	background-color: rgba(255,255,255,0.1);
	content: "";
	height: 0.0625rem;
	width: 100%;
}
.chain-modal-footer .chain-text {
	color: rgba(255, 255, 255, .5);
}




/* Login with Wallet */
.modal-dialog-scrollable .wallet-modal-content {
	max-height: 100%;
	overflow: hidden;
}
.wallet-modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #191A1A;
	background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.3));
	background-clip: padding-box;
	border-radius: 20px;
	outline: 0;
}
.wallet-modal-header {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	padding: 1.2rem 2rem;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	text-align: center;
}

.text-modal-title {
	font-weight: 600;
	font-size: 22px;
	color: #ffffff;
	background: linear-gradient(92deg, #8BFFF8 8%, #EFFCCA 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
}
.wallet-pop-close {
	position: absolute;
	right: 2rem;
	top: 1.5rem;
}
.close-svg {
	width: 20px;
	height: 20px;
	fill: #ffffff;
	opacity: 0.6;
	-webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.wallet-pop-close:hover .close-svg {
	opacity: 1;
}
.wallet-modal-body {
	overflow-y: auto;
	position: relative;
	flex: 1 1 auto;
	padding: 1rem 1.5rem;
	text-align: center;
	padding: 3rem;
}
@media (max-width: 767.98px) {
	.wallet-modal-body {
		padding: 1rem;
	}
}
.wallet-modal-footer {
	display: flex;
	flex-shrink: 0;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: 1.5rem 2rem;
	border-top: 1px solid rgba(255, 255, 255, 0);
	background-color: #252626;
}
.wallet-tp {
	width: 100%;
	text-align: center;
	line-height: 24px;
	font-weight: 500;
	letter-spacing: 0;
	font-size: 0.85rem;
}
@media (max-width: 767.98px) {
	.wallet-tp {
		font-size: 0.85rem;
	}
}
.wallet-content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
}
.wallet-btn {
	-webkit-box-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	height: auto;
	-webkit-box-pack: center;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	-webkit-box-align: center;
	align-items: center;
	border: 0px;
	border-radius: 16px;
	cursor: pointer;
	display: inline-flex;
	font-family: inherit;
	font-size: 16px;
	font-weight: 600;
	-webkit-box-pack: center;
	justify-content: center;
	letter-spacing: 0.03em;
	line-height: 1;
	opacity: 1;
	outline: 0px;
	transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
	min-height: 58px;
	padding: 16px 24px;
	background-color: #1E1E1E;
	color: rgb(31, 199, 212);
	box-shadow: none;
	width: 100%;
}
.wallet-svg {
	align-self: center;
	fill: rgb(244, 238, 255);
	flex-shrink: 0;
	margin-bottom: 8px;
	width: 40px;
	height: 40px;
}
.wallet-text {
	font-weight: 300;
	color: #ffffff;
	cursor: pointer;
}
.wallet-btn:hover {
	background-color: #2d2d2d;
}


/* Mobile devices do not support activation. Please go to the PC to activate the eligibility. */
.devices-modal-content {
	position: relative;
	display: flex;
	width: 100%;
	min-height: 240px;
	pointer-events: auto;
	background-color: #3a403f;
	background-clip: padding-box;
	clip-path: polygon(0 8%, 8% 0, 100% 0%, 100% 92%, 92% 100%, 0% 100%);
	outline: 0;
	clip-path: polygon(0 6%, 5% 0, 71% 0%, 74% 2%, 89% 2%, 92% 0, 100% 0, 100% 5%, 100% 92%, 96% 97%, 44% 97%, 41% 100%, 16% 100%, 14% 97%, 0 97%); 
}
.devices-modal-body {
	padding: 2rem 2rem 0 2rem;
	position: relative;
}
.devices-content {
	position: relative;
	height: 100%;
	padding: 2.5rem 5rem 0 1.5rem;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
	text-align: center;
	background-image: linear-gradient(161deg, rgb(0 0 0 / 67%), rgb(0 0 0 / 0%)), linear-gradient(125deg, rgb(255 255 255 / 13%), rgb(255 255 255 / 0%));
	border: 1px solid transparent;
	background-clip: padding-box, border-box;
	background-origin: padding-box, border-box;
}
.devices-content-text {
	font-weight: 600;
	font-size: 1rem;
	background-image: linear-gradient(130deg, #D2FFFC 0%, #FFE9A4 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.devices-img {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 35%;
}
.devices-pop-close {
	position: absolute;
	right: 1rem;
	top: 1rem;
	z-index: 1;
}
.three-dots {
	position: absolute;
	left: 1rem;
	top: 0.5rem;
	fill: rgba(255,255,255,.1);
}
@media (max-width: 767.98px) {
	.devices-content {
		padding: 2.7rem 3.5rem 0 2rem;
	}
	.devices-content-text {
		font-size: 1rem;
	}
	.devices-img {
		right: -5%;
		width: 40%;
	}
}

/* claim button */
.claim-btn {
	background-color: #74f0ee;
	display: inline-block;
	line-height: 1.5;
	color: #000000;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	border: none;
	font-size: 1.3rem;
	padding: 0.45rem 1rem;
	border-radius: 0.25rem;
	font-weight: 600;
	-webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	clip-path: polygon(10px 0px, 100% 0px, calc(100% - 10px) 0px, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0px calc(100% - 10px), 0px 10px);
}
.claim-btn:hover {
	color: #000;
	clip-path: polygon(5px 0px, 100% 0px, calc(100% - 5px) 0px, 100% 5px, 100% calc(100% - 5px), calc(100% - 5px) 100%, 5px 100%, 0px calc(100% - 5px), 0px 5px);
}
.claim-btn:disabled {
	pointer-events: none;
	opacity: .65;
	background: rgba(255,255,255,1);
}
.claim-btn.claimed {
color: rgba(116, 240, 238, 0.5);
background: rgb(65,78,78);
cursor: default;
}
.claim-btn.claimed:hover {
pointer-events: none;
clip-path: polygon(10px 0px, 100% 0px, calc(100% - 10px) 0px, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0px calc(100% - 10px), 0px 10px);
}
.claim-btn.claimed .i-success {
fill: rgba(116, 240, 238, 0.5);
margin-right: 0.2rem;
}